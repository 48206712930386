<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="agency-contact-container row">
      <div class=" top-container">
        <div class=" row">
          <div class="agency-image col-2" v-bind:style="{'background-image': (profilePicture ? 'url(/storage/' + profilePicture + ')' : 'url(/static/user.png)')}"></div>
          <div class="col-6 col-md-5 align-self-center">
            <div class="agency-name"> {{ name }} </div>
            <div class="registration-date">Регистрaция от: {{ date }}</div>
          </div>
          <div class="col-md-4">
            <Button class="roommate-button" :size="'medium'" :theme="'solid-grey'" :text="'Обади се'" />
          </div>
        </div>
      </div>
      <div class="middle-container">
        <div class="row ">
          <div class="col-md-4">
            <div class="agency-contact-item">Телефон:</div>
            <div v-for="phone in phones">
              {{ phone }}
            </div>
          </div>
          <div class="col-md-4" v-if="emails">
            <div class="agency-contact-item">email:</div>
            <div v-for="email in emails">
              {{ email }}
            </div>
          </div>
          <div class="col-md-4" v-if="address">
            <div class="agency-contact-item">Адрес:</div>
            <div>{{ address }}</div>
          </div>
        </div>
        </div>
      <div class="bottom-container" v-if="description">
        <div class="agency-info-desktop d-md-block d-none"> <div class="row">
          <a class="col-auto ">Относно</a>
          <a class="col-auto">Политика</a>
          <a class="col-auto">Гаранция</a>
          <a class="col-auto">Право на връщане</a>
        </div>
          <p>
            {{ description1 }}
          </p>
          <p>
            {{ description2 }}
          </p>
           <p>
            {{ description3 }}
          </p>
           <p>
            {{ description4 }}
          </p>
        </div>
        <div class="agency-info-mobile d-md-none">
          <div>
            <AboutButton :text="'Относно'"/>
            <p> {{ description1 }}</p>
          </div>
          <div>
            <AboutButton :text="'Политики'"/>
            <p> {{ description2 }}</p>
          </div>
            <div>
              <AboutButton :text="'Доставка'"/>
              <p> {{ description3 }}</p>
            </div>
            <div>
              <AboutButton :text="'Доставка'"/>
              <p> {{ description4 }}</p>
            </div>
        </div>
        <div class="socials d-flex">
          <div>Последвайте:</div>
          <a href="#" class="ms-3 me-2">
            <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_100_5879)">
                <path d="M8.49698 9.25861C7.5997 9.25861 6.7364 9.25861 5.85272 9.25861C5.85272 11.8479 5.85272 14.4172 5.85272 16.9998C4.7855 16.9998 3.73187 16.9998 2.66465 16.9998C2.66465 14.4238 2.66465 11.8545 2.66465 9.25861C1.76737 9.25861 0.890483 9.25861 0 9.25861C0 8.24021 0 7.24843 0 6.23003C0.876888 6.23003 1.75378 6.23003 2.65786 6.23003C2.65786 6.1435 2.65786 6.08359 2.65786 6.01703C2.65786 5.22494 2.63746 4.43285 2.67145 3.64076C2.71903 2.5691 3.06571 1.61061 3.91541 0.885078C4.52719 0.372549 5.24773 0.112956 6.04305 0.0397372C6.90634 -0.0467937 7.76964 0.0197685 8.63293 0.092987C8.75529 0.106299 8.87764 0.112956 9.0068 0.119612C9.0068 1.02486 9.0068 1.91679 9.0068 2.82204C8.86405 2.82204 8.73489 2.82204 8.59894 2.82204C8.07553 2.8287 7.54532 2.82204 7.0219 2.85532C6.30136 2.89526 5.9003 3.26801 5.86631 3.97357C5.83233 4.71241 5.85952 5.45791 5.85952 6.22337C6.86556 6.22337 7.8716 6.22337 8.90483 6.22337C8.76208 7.24843 8.63293 8.24021 8.49698 9.25861Z" fill="black"/>
              </g>
              <defs>
                <clipPath id="clip0_100_5879">
                  <rect width="9" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          <a href="#" >
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_100_5875)">
                <path d="M16.9933 8.6794C16.9933 9.58394 17 10.4951 16.9933 11.3997C16.9867 12.2377 16.9333 13.0691 16.6467 13.8672C16.0667 15.4701 14.9133 16.4278 13.26 16.7936C12.3333 16.9998 11.3867 16.9799 10.4467 16.9865C9.07333 16.9998 7.69333 16.9998 6.32 16.9865C5.46 16.9732 4.6 16.9799 3.75333 16.7936C2.28667 16.4744 1.19333 15.6829 0.54 14.3128C0.233333 13.6743 0.0733333 12.9826 0.06 12.2776C0.0266667 10.2623 0 8.23378 0 6.21187C0 5.38715 0.02 4.56242 0.186667 3.751C0.586667 1.8355 2.00667 0.472045 3.94 0.159447C4.97333 -0.00682815 6.01333 -0.000177129 7.06 -0.000177129C8.58667 -0.000177129 10.1133 0.0131249 11.6333 0.033078C12.5267 0.04638 13.4133 0.139494 14.2467 0.5053C15.6667 1.13715 16.5133 2.24122 16.82 3.74435C16.9333 4.30303 16.9733 4.88167 16.9867 5.45366C17.0133 6.53112 16.9933 7.60194 16.9933 8.6794ZM15.52 8.50647C15.5133 8.50647 15.5 8.50647 15.4933 8.50647C15.4933 8.06086 15.5067 7.60859 15.4933 7.16297C15.46 6.20522 15.4533 5.24082 15.3533 4.28308C15.22 2.98613 14.38 2.07494 13.1133 1.74904C12.4333 1.56946 11.7333 1.56946 11.0333 1.56281C9.60667 1.54951 8.18 1.54951 6.76 1.55616C5.89333 1.55616 5.02667 1.53621 4.17333 1.69583C2.99333 1.91532 2.16667 2.56046 1.79333 3.71774C1.58667 4.36289 1.56 5.03464 1.56 5.69974C1.54667 7.56868 1.54 9.43762 1.56667 11.3066C1.57333 11.8785 1.62 12.4572 1.73333 13.0159C1.95333 14.0933 2.60667 14.8249 3.66667 15.1841C4.23333 15.377 4.82667 15.4169 5.42 15.4302C6.30667 15.4501 7.19333 15.4634 8.08 15.4634C9.56667 15.4634 11.0533 15.51 12.5333 15.3637C13.2067 15.2972 13.8267 15.0843 14.3533 14.6387C15.1133 13.9936 15.3867 13.1223 15.4267 12.1778C15.48 10.954 15.4933 9.73026 15.52 8.50647Z" fill="black"/>
                <path d="M8.50646 12.863C6.09313 12.8564 4.12646 10.8943 4.12646 8.49332C4.12646 6.08565 6.09313 4.13025 8.50646 4.13025C10.9265 4.13025 12.8798 6.08565 12.8798 8.49332C12.8798 10.9143 10.9131 12.8697 8.50646 12.863ZM8.51313 11.3266C10.0931 11.3266 11.3465 10.063 11.3398 8.48667C11.3331 6.92368 10.0731 5.67329 8.4998 5.67329C6.92646 5.67329 5.67313 6.93033 5.67313 8.49997C5.67313 10.0696 6.93313 11.3266 8.51313 11.3266Z" fill="black"/>
                <path d="M14.0734 3.95735C14.0734 4.52934 13.6201 4.97495 13.0534 4.97495C12.4868 4.97495 12.0334 4.51603 12.0401 3.9507C12.0401 3.39201 12.4934 2.94639 13.0534 2.94639C13.6268 2.93974 14.0734 3.38536 14.0734 3.95735Z" fill="black"/>
              </g>
              <defs>
                <clipPath id="clip0_100_5875">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
  </div>
  </div>
</template>

