<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container" class="container" v-if="item">

    <div class="row  d-flex">
      <div class="col-md-6">
        <AgencyInfoBox :name="item.name" :date="item.date" :description1="item.description" :phones="item.phones"
                       :emails="item.emails" :profile-picture="item.profilePicture"/>
      </div>
      <div class="col-md-6 search-results-list">
        <div class="row search-results-nav">
          <SingleAgencyFilters :resultsamount="'12'"/>
        </div>
        <div class="row property-results-container">
          <div class="col-md-6 propert-ad" v-for="property in properties">
            <PropertyAd
                :property="property"
                :id="property.id"
                :adtype="property.isVip ? 'premium' : 'regular'"
                :city="property.city.name"
                :district="property.district.name"
                :title="property.title"
                :amount="property.price"
                :images="property.images"
            />
          </div>
        </div>
        <div class="show-more-results">
          <Button :theme="'solid-grey'" :size="'medium'" :text="'Покажи още резултати'"/>
        </div>


      </div>

    </div>


  </div>
  <div class="">
    <Footer/>
  </div>
</template>

