import Button from '@/components/Common/Buttons/Button/Button';
import PropertyAd from '@/components/Components/PropertyAd/PropertyAd';
import AgencyInfoBox from '@/views/SingleAgencyPage/AgencyInfoBox/AgencyInfoBox';
import SingleAgencyFilters from '@/views/SingleAgencyPage/SingleAgencyFilters/SingleAgencyFilters';

export default {
    name: 'SingleAgencyPage',
    components: {
        Button,
        PropertyAd,
        AgencyInfoBox,
        SingleAgencyFilters,
    },
    data() {
        return {
            item: null,
            properties: null
        };
    },
    async mounted() {
        const id = this.$route.params.id;
        const filter = {};

        if (this.$route.name === 'Agency') {
            const agency = await this.$store.dispatch('agency/getAgency', id);
            this.setItem(agency.name, agency.createdAt, "Dsadas", agency.phoneCollection, agency.emailCollection, agency.logo);
            filter.agencyId = agency.id;
        } else {
            const user = await this.$store.dispatch('user/getUser', id);
            this.setItem(user.name, user.createdAt, "Dsadas", [user.phone], user.emailCollection, user.profilePicture);
            filter.userId = user.id;
        }

        this.properties = await this.$store.dispatch('property/getProperties', {params: filter, limit: 10});
    },
    methods: {
        async setItem(name, date, description, phones, emails, profilePicture) {
            this.item = {
                name,
                date,
                description,
                phones,
                emails,
                profilePicture
            }
        }
    },
};
