<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="search-results-nav-container ">
      <div class="d-flex align-items-center search-results-nav-desktop">
        <div class="me-auto col-4 result-number"> <span>{{ resultsamount }}</span> активни обяви</div>
        <div class=" d-flex">
          <div class="col-4 sort-by me-1">Подреди по:</div>
          <div class=" col-8 d-flex align-items-center sort-by-button me-3">
            <div class="me-1">Дата на добавяне</div>
            <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.66675 1.16667L4.66675 11.8333" stroke="#FF7A00" stroke-linecap="round"/>
              <path d="M0.666749 7.83334L4.66675 11.8333L8.66675 7.83334" stroke="#FF7A00" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>


      </div>
    </div>

  </div>

</template>

